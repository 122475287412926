main {
  color: $primary;
  background-color: $digicraft-black;
}

html {
  scroll-behavior: smooth;
}

.hover-primary {
  &:hover {
    color: $p2;
    transition: 0.5s;
    cursor: context-menu;
  }
}

[v-cloak] {
  display: none;
}