div.nav-container {
    padding: 10px 0 150px;

    @include media-breakpoint-up(lg) {
        padding: 30px 0 150px;
    }

    nav.navbar {
        height: 95px;
        background-color: transparent;
        z-index: 2;

        @include media-breakpoint-up(md) {
            height: 90px;
        }

        .navbar-collapse {
            background: #000;
            z-index: 5;
            width: 100%;
            padding: 0;
            position: absolute;
            top: 86px;
            left: 0;

            @include media-breakpoint-up(lg) {
                top: 0;
                background: transparent;
            }
        }
    }

    img.brand-logo {
        width: 150px;
        height: 100%;

        @include media-breakpoint-up(md) {
            width: 250px;
        }
    }

    li.nav-item {
        font-size: 18px;
        padding: 18px 24px;

        @include media-breakpoint-up(md) {
            font-size: 28px;
        }

        @include media-breakpoint-up(lg) {
            font-size: 18px;
        }

        a.nav-link {
            color: $primary;
        }
    }

    ul.customBurgerMenu {
        position: absolute;
        top: 50%;
        right: 7px;
        transform: translate(-50%, -50%);
        width: 32px;
        height: 32px;
        cursor: pointer;

        @include media-breakpoint-up(md) {
            width: 50px;
            height: 50px
        }

        li.burger-bar {
            list-style: none;
            width: 100%;
            height: 3px;
            background: #fff;
            position: absolute;
            left: 0;
            transform: translateY(-50%);
            box-shadow: 0 0 7px rgba(0, 0, 0, .5);
            transition: .9s;

            @include media-breakpoint-up(md) {
                height: 7px;
            }

            &:nth-of-type(1) {
                top: 20%;
            }

            &:nth-of-type(2) {
                top: 50%;
            }

            &:nth-of-type(3) {
                top: 80%;
            }

            &:nth-of-type(3) {
                top: 80%;
            }

            &:last-child {
                width: 100%;
                height: auto;
                transform: none;
                top: 100%;
                font-size: 14px;
                text-align: center;
                transition: .4s;

                @include media-breakpoint-up(md) {
                    font-size: 16px;
                }

                &::before,
                &::after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    transition: .4s;
                }

                &:last-child::before {
                    content: 'Menu';
                    transform: rotateY(0);
                    color: #fff;
                    transition: .2s;
                }

                &:last-child::after {
                    content: 'Close';
                    color: #fff;
                    transform: rotateY(90deg);
                    transition: .2s;
                }
            }
        }

        &.active {
            li.burger-bar {
                &:nth-of-type(1) {
                    top: 50%;
                    transform: translateY(-50%) rotate(45deg) rotateY(180deg);
                }

                &:nth-of-type(2) {
                    left: 300%;
                    opacity: 0;
                }

                &:nth-of-type(3) {
                    top: 50%;
                    transform: translateY(-50%) rotate(-45deg) rotateY(180deg);
                }

                &:last-child::before {
                    transform: rotateY(90deg);
                    transition: .2s;
                }

                &:last-child::after {
                    transform: rotateY(0deg);
                    transition: .2s;
                }
            }
        }
    }
}