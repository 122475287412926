h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: $primary;
  font-family: $headings-font-family;
  line-height: 0.9;
  font-weight: 500;
}

h1,
.h1,
.display-1 {
  @include media-breakpoint-up(lg) {
    font-size: $display1-size;
  }
}

h2,
.h2 {
  @include media-breakpoint-up(lg) {
    font-size: $display2-size;
  }
}

h3,
.h3 {
  @include media-breakpoint-up(lg) {
    font-size: $display3-size;
  }
}

h4,
.h4, .display-4 {
  @include media-breakpoint-up(lg) {
    font-size: $display4-size;
  }
}

h5,
.h5, .display-5 {
  @include media-breakpoint-up(lg) {
    font-size: $display5-size;
  }
}

h6,
.h6 {
  @include media-breakpoint-up(lg) {
    font-size: $display6-size;
  }
}

a,
.nav-link {
  font-family: $font-family-base;

  &:hover,
  &:focus {
    color: $p2  !important;
    transition: color 400ms ease;
  }
}

label {
  font-family: $headings-font-family;
  font-weight: 500;
}

.w-30 {
  width: 30% !important;
}

.w-md-30 {
  @include media-breakpoint-up(md) {
    width: 30% !important;
  }
}

.w-md-50 {
  @include media-breakpoint-up(md) {
    width: 50% !important;
  }
}

.w-lg-50 {
  @include media-breakpoint-up(lg) {
    width: 50% !important;
  }
}


.w-lg-75 {
  @include media-breakpoint-up(lg) {
    width: 75% !important;
  }
}

.text-primary {
  color: $p2;
}

.text-secondary {
  color: $s1;
}

p {
  font-family: $font-family-base;
  font-size: 16px;
}

.lead {
  font-family: $font-family-base;
  font-size: 18px;
}

.container {
  @include media-breakpoint-down(lg) {
    padding-right: calc(var(--bs-gutter-x) * 1);
    padding-left: calc(var(--bs-gutter-x) * 1);
  }
}

.zindex-1 {
  z-index: 1;
}
.zindex-2 {
  z-index: 2;
}
.zindex-3 {
  z-index: 3;
}
.zindex-4 {
  z-index: 4;
}
.zindex-5 {
  z-index: 5;
}