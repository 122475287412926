.hero {
  margin-bottom: 40px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 90px;
  }

  .hero-img-container {
    right: 0;
    top: 140px;

    @include media-breakpoint-up(lg) {
      top: 0;
    }

    img.hero-img {
      width: 100%;
      height: auto;

      @include media-breakpoint-up(md) {
        height: 37vh;
      }

      @include media-breakpoint-up(lg) {
        height: 70vh;
      }
    }

    .overlay {
      background-color: #000;
      width: 100%;
      opacity: .25;
      height: 37vh;
      top: 0;
      right: 0;

      @include media-breakpoint-up(md) {
        height: 37vh;
      }

      @include media-breakpoint-up(lg) {
        height: 70vh;
      }
    }
  }
}

.how-we-help-container {
  margin-top: -6%;

  @include media-breakpoint-up(lg) {
    margin-top: -4%;
  }

  .img-container {
    width: 100%;

    @include media-breakpoint-up(lg) {
      width: 400px;
      min-width: 400px;
    }

    img {
      width: 100%;
      height: 100%;
    }

    .overlay {
      position: absolute;
      background-color: #000;
      width: 100%;
      opacity: .25;
      height: 100%;
      top: 0;
    }
  }

  .description-container {

    // padding: 40px;
    p.description {
      font-weight: 500;
    }

    .card {
      min-width: 50%;
      margin-right: 30px;

      .card-body {
        @include media-breakpoint-down(lg) {
          padding-left: 0;
        }
      }
    }
  }
}
.clients-container {
  .clients-logo {
    height: 100%;
    width: 150px;
    margin-right: 50px;
    margin-bottom: 50px;
  }
}
